import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

interface IProps {
  stat: string;
}

export const WWStat: React.FC<IProps> = ({ stat }) => {
  return (
    <div className="ww-stat">
      {stat === 'HP' && (
        <>
          <StaticImage src="../../../../images/ww/icons/stat_hp.png" alt="HP" />
          <span>HP</span>
        </>
      )}
      {stat === 'HP%' && (
        <>
          <StaticImage src="../../../../images/ww/icons/stat_hp.png" alt="HP" />
          <span>HP%</span>
        </>
      )}
      {stat === 'DEF' && (
        <>
          <StaticImage
            src="../../../../images/ww/icons/stat_def.png"
            alt="DEF"
          />
          <span>DEF</span>
        </>
      )}
      {stat === 'DEF%' && (
        <>
          <StaticImage
            src="../../../../images/ww/icons/stat_def.png"
            alt="DEF"
          />
          <span>DEF%</span>
        </>
      )}
      {stat === 'ATK' && (
        <>
          <StaticImage
            src="../../../../images/ww/icons/stat_atk.png"
            alt="ATK"
          />
          <span>ATK</span>
        </>
      )}
      {stat === 'ATK%' && (
        <>
          <StaticImage
            src="../../../../images/ww/icons/stat_atk.png"
            alt="ATK"
          />
          <span>ATK%</span>
        </>
      )}
      {stat === 'CRIT Rate' && (
        <>
          <StaticImage
            src="../../../../images/ww/icons/stat_crit.png"
            alt="CRIT Rate"
          />
          <span>CRIT Rate</span>
        </>
      )}
      {stat === 'CRIT DMG' && (
        <>
          <StaticImage
            src="../../../../images/ww/icons/stat_critdmg.png"
            alt="CRIT DMG"
          />
          <span>CRIT DMG</span>
        </>
      )}
      {stat === 'Healing Bonus' && (
        <>
          <StaticImage
            src="../../../../images/ww/icons/stat_heal.png"
            alt="Healing Bonus"
          />
          <span>Healing Bonus</span>
        </>
      )}
      {stat === 'Energy Regen' && (
        <>
          <StaticImage
            src="../../../../images/ww/icons/stat_energy.png"
            alt="Energy Regen"
          />
          <span>Energy Regen</span>
        </>
      )}
      {stat === 'Aero DMG' && (
        <>
          <StaticImage
            src="../../../../images/ww/icons/stat_wind.png"
            alt="Aero DMG"
          />
          <span className="Aero">Aero DMG</span>
        </>
      )}
      {stat === 'Electro DMG' && (
        <>
          <StaticImage
            src="../../../../images/ww/icons/stat_ele.png"
            alt="Stat"
          />
          <span className="Electro">Electro DMG</span>
        </>
      )}
      {stat === 'Fusion DMG' && (
        <>
          <StaticImage
            src="../../../../images/ww/icons/stat_fire.png"
            alt="Stat"
          />
          <span className="Fusion">Fusion DMG</span>
        </>
      )}
      {stat === 'Glacio DMG' && (
        <>
          <StaticImage
            src="../../../../images/ww/icons/stat_ice.png"
            alt="Stat"
          />
          <span className="Glacio">Glacio DMG</span>
        </>
      )}
      {stat === 'Havoc DMG' && (
        <>
          <StaticImage
            src="../../../../images/ww/icons/stat_dark.png"
            alt="Stat"
          />
          <span className="Havoc">Havoc DMG</span>
        </>
      )}
      {stat === 'Spectro DMG' && (
        <>
          <StaticImage
            src="../../../../images/ww/icons/stat_spec.png"
            alt="Stat"
          />
          <span className="Spectro">Spectro DMG</span>
        </>
      )}
    </div>
  );
};
