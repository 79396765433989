import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Tab, Table, Tabs } from 'react-bootstrap';
import { WWStat } from '../../../modules/ww/common/components/ww-stat';

const WWGuidesEchoesStats: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Echoes Stats</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_echo3.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Echoes Stats</h1>
          <h2>List of all available Echoes Stats in Wuthering Waves.</h2>
          <p>
            Last updated: <strong>22/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Echo Stats" />
        <div className="banner ready">
          <div className="cta">
            <p>
              The Echoes Stats have been updated with{' '}
              <strong>release information</strong>!
            </p>
          </div>
        </div>
        <StaticImage
          src="../../../images/ww/generic/echo_ex.webp"
          alt="Guide"
        />
        <p>Information about Echo stats:</p>
        <ul>
          <li>
            Each Echo has <strong>2 main stats</strong>, determined by the
            Echo’s COST,
          </li>
          <ul>
            <li>
              1 COST Echoes always have Flat HP as their secondary main stat,
            </li>
            <li>
              3 and 4 COST Echoes always have Flat ATK as their secondary main
              stat,
            </li>
            <li>
              <strong>Primary main stats are randomized</strong> according to
              the Echo’s COST.
            </li>
          </ul>
          <li>
            Each Echo can have up <strong>to 5 sub-stats</strong>, where the
            maximum number of them is determined by the Echo’s rarity,
          </li>
          <li>
            A sub-stat slot will be available for tuning for every 5 levels an
            Echo has with a maximum of 5 slots at level 25,
          </li>
          <li>
            An Echo can’t have duplicate sub-stats, but its{' '}
            <strong>sub-stats can be the same as the main stat.</strong>
          </li>
        </ul>
        <SectionHeader title="Main Stats" />
        <p>
          Each column shows a pair of values:{' '}
          <strong>
            the main stat at +0 and at +10/+15/+20/+25 (depending on the rarity
            of the Echo)
          </strong>
          . There are no random rolls here - you always get the same main stat
          value once you reach a certain enhancement level.
        </p>
        <p>
          Switch between the tabs to check the main stats for different ranks of
          Echoes.{' '}
          <strong>
            Note that Flat HP/ATK/DEF are secondary main stats while the rest
            are primary main stats.
          </strong>
        </p>
        <Tabs
          defaultActiveKey="common"
          id="main-stats"
          className="ww-tabs main-stats"
          transition={false}
        >
          <Tab
            eventKey="common"
            title={
              <>
                <strong className="Common">Common</strong> (1 cost)
              </>
            }
          >
            <Table striped bordered responsive="sm" className="ww-stat-table">
              <thead>
                <tr>
                  <th className="first">Stat</th>
                  <th>
                    <strong className="Rank-2">Rank 2</strong>
                  </th>
                  <th>
                    <strong className="Rank-3">Rank 3</strong>
                  </th>
                  <th>
                    <strong className="Rank-4">Rank 4</strong>
                  </th>
                  <th>
                    <strong className="Rank-5">Rank 5</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="first">
                    <WWStat stat="HP" />
                  </td>
                  <td>114 - 296</td>
                  <td>152 - 516</td>
                  <td>228 - 957</td>
                  <td>456 - 2280</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="HP%" />
                  </td>
                  <td>2.8% - 7.2%</td>
                  <td>3.0% - 10.20%</td>
                  <td>3.4% - 14.2%</td>
                  <td>4.5% - 22.8%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="ATK%" />
                  </td>
                  <td>2.2% - 5.7%</td>
                  <td>2.4% - 8.1%</td>
                  <td>2.7% - 11.3%</td>
                  <td>3.6% - 18.0%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="DEF%" />
                  </td>
                  <td>2.2% - 5.7%</td>
                  <td>2.4% - 8.1%</td>
                  <td>2.7% - 11.3%</td>
                  <td>3.6% - 18.0%</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
          <Tab
            eventKey="elite"
            title={
              <>
                <strong className="Elite">Elite</strong> (3 cost)
              </>
            }
          >
            <Table striped bordered responsive="sm" className="ww-stat-table">
              <thead>
                <tr>
                  <th className="first">Stat</th>
                  <th>
                    <strong className="Rank-2">Rank 2</strong>
                  </th>
                  <th>
                    <strong className="Rank-3">Rank 3</strong>
                  </th>
                  <th>
                    <strong className="Rank-4">Rank 4</strong>
                  </th>
                  <th>
                    <strong className="Rank-5">Rank 5</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="first">
                    <WWStat stat="ATK" />
                  </td>
                  <td>12 - 31</td>
                  <td>13 - 44</td>
                  <td>15 - 63</td>
                  <td>20 - 100</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="HP%" />
                  </td>
                  <td>3.7% - 9.6%</td>
                  <td>4.0% - 14.0%</td>
                  <td>4.5% - 18.9%</td>
                  <td>6.0% - 30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="ATK%" />
                  </td>
                  <td>3.7% - 9.6%</td>
                  <td>4.0% - 14.0%</td>
                  <td>4.5% - 18.9%</td>
                  <td>6.0% - 30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="DEF%" />
                  </td>
                  <td>4.7% - 12.3%</td>
                  <td>5.0% - 17%</td>
                  <td>5.7% - 23.9%</td>
                  <td>7.6% - 38%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="Aero DMG" />
                  </td>
                  <td>3.7% - 9.6%</td>
                  <td>4.0% - 14%</td>
                  <td>4.5% - 18.9%</td>
                  <td>6.0% - 30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="Glacio DMG" />
                  </td>
                  <td>3.7% - 9.6%</td>
                  <td>4.0% - 14%</td>
                  <td>4.5% - 18.9%</td>
                  <td>6.0% - 30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="Fusion DMG" />
                  </td>
                  <td>3.7% - 9.6%</td>
                  <td>4.0% - 14%</td>
                  <td>4.5% - 18.9%</td>
                  <td>6.0% - 30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="Electro DMG" />
                  </td>
                  <td>3.7% - 9.6%</td>
                  <td>4.0% - 14%</td>
                  <td>4.5% - 18.9%</td>
                  <td>6.0% - 30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="Havoc DMG" />
                  </td>
                  <td>3.7% - 9.6%</td>
                  <td>4.0% - 14%</td>
                  <td>4.5% - 18.9%</td>
                  <td>6.0% - 30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="Spectro DMG" />
                  </td>
                  <td>3.7% - 9.6%</td>
                  <td>4.0% - 14%</td>
                  <td>4.5% - 18.9%</td>
                  <td>6.0% - 30%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="Energy Regen" />
                  </td>
                  <td>?? - 10%</td>
                  <td>4.2% - 14.2%</td>
                  <td>4.8% - 20.1%</td>
                  <td>6.4% - 32.0%</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
          <Tab
            eventKey="overlord"
            title={
              <>
                <strong className="Overlord">Overlord</strong> &{' '}
                <strong className="Calamity">Calamity</strong> (4 cost)
              </>
            }
          >
            <Table striped bordered responsive="sm" className="ww-stat-table">
              <thead>
                <tr>
                  <th className="first">Stat</th>
                  <th>
                    <strong className="Rank-2">Rank 2</strong>
                  </th>
                  <th>
                    <strong className="Rank-3">Rank 3</strong>
                  </th>
                  <th>
                    <strong className="Rank-4">Rank 4</strong>
                  </th>
                  <th>
                    <strong className="Rank-5">Rank 5</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="first">
                    <WWStat stat="ATK" />
                  </td>
                  <td>18 - 46</td>
                  <td>20 - 68</td>
                  <td>22 - 92</td>
                  <td>30 - 150</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="HP%" />
                  </td>
                  <td>4.1% - 10.6%</td>
                  <td>4.3% - 14.6%</td>
                  <td>4.9% - 20.5%</td>
                  <td>6.6% - 33.0%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="ATK%" />
                  </td>
                  <td>4.1% - 10.6%</td>
                  <td>4.3% - 14.6%</td>
                  <td>4.9% - 20.5%</td>
                  <td>6.6% - 33.0%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="DEF%" />
                  </td>
                  <td>5.2% - 13.5%</td>
                  <td>5.5% - 18.7%</td>
                  <td>6.2% - 26.0%</td>
                  <td>8.3% - 41.5%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="CRIT Rate" />
                  </td>
                  <td>2.7% - 7.1%</td>
                  <td>2.9% - 9.8%</td>
                  <td>3.3% - 13.8%</td>
                  <td>4.4% - 22.0%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="CRIT DMG" />
                  </td>
                  <td>?? - 14.3%</td>
                  <td>5.8% - 19.7%</td>
                  <td>6.6% - 27.7%</td>
                  <td>8.8% - 44.0%</td>
                </tr>
                <tr>
                  <td className="first">
                    <WWStat stat="Healing Bonus" />
                  </td>
                  <td>?? - 8.5%</td>
                  <td>3.5% - 11.9%</td>
                  <td>3.9% - 16.3%</td>
                  <td>5.2% - 26.0%</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
        </Tabs>
        <SectionHeader title="Sub-stats" />
        <p>
          Since the sub-stat pool is the same no matter the Echo COST, there’s
          no point in splitting them. Just remember that you can’t roll the same
          sub-stat more than once on the same Echo.{' '}
        </p>
        <h5>Sub-stats ranges</h5>
        <p>
          This table shows the possible sub-stats values you can get upon
          Tuning.{' '}
          <strong>
            Reminder that sub-stats cannot be changed or upgraded, and all
            rarities have the same sub-stat range.
          </strong>
        </p>
        <p>
          <strong>Important!</strong> If you’ve got an Echo that rolled
          different values, please let us know and we will fix the data below.
        </p>
        <Table striped bordered responsive="sm" className="ww-stat-table subs">
          <thead>
            <tr>
              <th className="first">Stat</th>
              <th>Range</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="first">
                <WWStat stat="HP" />
              </td>
              <td>320 - 580</td>
            </tr>
            <tr>
              <td className="first">
                <WWStat stat="ATK" />
              </td>
              <td>30 - 60</td>
            </tr>
            <tr>
              <td className="first">
                <WWStat stat="DEF" />
              </td>
              <td>40 - 70</td>
            </tr>
            <tr>
              <td className="first">
                <WWStat stat="HP%" />
              </td>
              <td>6.4% - 11.6%</td>
            </tr>
            <tr>
              <td className="first">
                <WWStat stat="ATK%" />
              </td>
              <td>6.4% - 11.6%</td>
            </tr>
            <tr>
              <td className="first">
                <WWStat stat="DEF%" />
              </td>
              <td>8.1% - 14.7%</td>
            </tr>
            <tr>
              <td className="first">
                <WWStat stat="CRIT Rate" />
              </td>
              <td>6.3% - 10.5%</td>
            </tr>
            <tr>
              <td className="first">
                <WWStat stat="CRIT DMG" />
              </td>
              <td>12.6% - 21%</td>
            </tr>
            <tr>
              <td className="first">
                <WWStat stat="Energy Regen" />
              </td>
              <td>6.8% - 12.4%</td>
            </tr>
            <tr>
              <td className="first">
                <div className="ww-stat">
                  <span>Basic Attack DMG Bonus</span>
                </div>
              </td>
              <td>6.4% - 11.6%</td>
            </tr>
            <tr>
              <td className="first">
                <div className="ww-stat">
                  <span>Heavy Attack DMG Bonus</span>
                </div>
              </td>
              <td>6.4% - 11.6%</td>
            </tr>
            <tr>
              <td className="first">
                <div className="ww-stat">
                  <span>Resonance Skill DMG Bonus</span>
                </div>
              </td>
              <td>6.4% - 11.6%</td>
            </tr>
            <tr>
              <td className="first">
                <div className="ww-stat">
                  <span>Resonance Liberation DMG Bonus</span>
                </div>
              </td>
              <td>6.4% - 11.6%</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesEchoesStats;

export const Head: React.FC = () => (
  <Seo
    title="Echoes Stats | Wuthering Waves | Prydwen Institute"
    description="List of all available Echoes Stats in Wuthering Waves."
    game="ww"
  />
);
